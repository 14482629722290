import { IOrganization, OrgUtils } from "@smartrr/shared/entities/Organization";
import { IPurchaseState } from "@smartrr/shared/entities/PurchaseState";
import { ScheduledDeliveryGroupWithPurchSt } from "@smartrr/shared/entities/PurchaseState/scheduleUtils";
import { RRuleManager } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { getTotalDisplay } from "@smartrr/shared/utils/displayUtils";
import { getScheduledDeliveriesForCpsGroup } from "@smartrr/shared/utils/getScheduledDeliveries";
import { flatten } from "lodash";

import { IScheduledEventWithProjectedTotal } from "../..";
import { getFinalNumberOfdeliveriesToGenerate } from "../getFinalNumberOfDeliveriesToGenerate";
import { getFuturePausedDeliveries } from "../getFuturePausedDeliveries";

export function getPurcStScheduledDeliveriesFromDateWithTotal<T extends IPurchaseState>(
  purchaseState: T,
  customerPurchaseStates: T[],
  organization: IOrganization | null,
  numberOfFutureDeliveriesToGenerate = 5,
  shouldIncludeNonActive: boolean
): IScheduledEventWithProjectedTotal[] {
  if (numberOfFutureDeliveriesToGenerate < 1) {
    return [];
  }

  const considerPurchaseState =
    shouldIncludeNonActive || purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active;

  let futureDeliveries: ScheduledDeliveryGroupWithPurchSt<T>[] =
    // make consistent for single purchase vs. not
    organization && considerPurchaseState && purchaseState.schedule
      ? getScheduledDeliveriesForCpsGroup(
          organization,
          [purchaseState],
          numberOfFutureDeliveriesToGenerate,
          shouldIncludeNonActive
        ).map(delivery => {
          return { ...delivery[1][0], purchaseState };
        })
      : [];
  return flatten(
    customerPurchaseStates.map(cps => {
      if (!cps.schedule || !organization) {
        return [];
      }

      const finalNumberOfDeliveriesToGenerate = getFinalNumberOfdeliveriesToGenerate(
        cps,
        numberOfFutureDeliveriesToGenerate
      );
      const vendorSchedule = OrgUtils.getBillingSchedule(organization);

      const rruleManager = RRuleManager.fromJson(cps.schedule, vendorSchedule);

      // Select start date of new schedule so that it starts on specified delivery date and billing time
      // or on the nearest anchor after the delivery date

      if (cps.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Paused && cps.unpauseDate) {
        futureDeliveries = getFuturePausedDeliveries(
          cps,
          organization,
          rruleManager,
          finalNumberOfDeliveriesToGenerate
        );
      } else if (cps.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active) {
        futureDeliveries =
          // make consistent for single purchase vs. not
          cps.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active
            ? rruleManager
                .getFutureScheduledDeliveries(finalNumberOfDeliveriesToGenerate, organization.setup?.billingDays)
                .map(delivery => ({ ...delivery, purchaseState: cps }))
            : [];
      }

      return futureDeliveries.map(scheduledEvent => {
        const totalForScheduleIdx = getTotalDisplay(
          scheduledEvent.paymentMultipleDueOnDate,
          cps,
          scheduledEvent.indexFromScheduleStart,
          true,
          scheduledEvent.indexFromNext
        );

        return { ...scheduledEvent, totalForScheduleIdx };
      });
    })
  );
}
