import { RRule } from "rrule";

import { SubscriptionContractSubscriptionStatus } from "../../shopifyGraphQL/api";
import { OrgUtils } from "../Organization";
import { RRuleManager, ScheduledEvent } from "../Schedule/RRuleManager";

import { IPurchaseState } from "./index";

export interface ScheduledDeliveryGroupWithPurchSt<T extends IPurchaseState = IPurchaseState>
  extends ScheduledEvent {
  purchaseState: T;
}

function getFutureDeliveries<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  numberOfFutureDeliveriesToGenerate: number,
  shouldIncludeNonActive: boolean
): ScheduledDeliveryGroupWithPurchSt<T>[] {
  const billingDays = OrgUtils.getOrgBillingDays(purchaseState.organization);
  const considerPurchaseState =
    shouldIncludeNonActive || purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active;

  // make consistent for single purchase vs. not
  return considerPurchaseState && purchaseState.schedule
    ? RRuleManager.fromJson(purchaseState.schedule, vendorSchedule)
        .getFutureScheduledDeliveries(numberOfFutureDeliveriesToGenerate, billingDays)
        .map(delivery => ({ ...delivery, purchaseState }))
    : [];
}

export function getPurchStFutureScheduledDeliveriesWithNonActive<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  numberOfFutureDeliveriesToGenerate: number
) {
  return getFutureDeliveries(purchaseState, vendorSchedule, numberOfFutureDeliveriesToGenerate, true);
}

export function getPurchStFutureScheduledDeliveries<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  numberOfFutureDeliveriesToGenerate: number
) {
  return getFutureDeliveries(purchaseState, vendorSchedule, numberOfFutureDeliveriesToGenerate, false);
}

export function getPurchStFutureActualDeliveries<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  numberOfFutureDeliveriesToGenerate: number
): ScheduledDeliveryGroupWithPurchSt<T>[] {
  const billingDays = OrgUtils.getOrgBillingDays(purchaseState.organization);

  // make consistent for single purchase vs. not
  return purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active &&
    purchaseState.schedule
    ? RRuleManager.fromJson(purchaseState.schedule, vendorSchedule)
        .getFutureActualDeliveries(numberOfFutureDeliveriesToGenerate, billingDays)
        .map(delivery => ({ ...delivery, purchaseState }))
    : [];
}

export function getPurchStFutureScheduledDeliveriesUntilDate<T extends IPurchaseState>(
  purchaseState: T,
  vendorSchedule: RRule,
  untilDate: Date
): ScheduledDeliveryGroupWithPurchSt<T>[] {
  const billingDays = OrgUtils.getOrgBillingDays(purchaseState.organization);

  // make consistent for single purchase vs. not
  return purchaseState.purchaseStateStatus === SubscriptionContractSubscriptionStatus.Active &&
    purchaseState.schedule
    ? RRuleManager.fromJson(purchaseState.schedule, vendorSchedule)
        .getFutureScheduledDeliveriesUntilDate(untilDate, billingDays)
        .map(delivery => ({ ...delivery, purchaseState }))
    : [];
}
